import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { lazy, useEffect } from 'react';
import { Layout } from './Layout';
import { NotFound } from 'pages/NotFound/NotFound';

// Головні сторінки
const Home = lazy(() => import('../pages/Home/Home'));
const BlogPage = lazy(() => import('../pages/BlogPage/BlogPage'));
const ContactsPage = lazy(() => import('../pages/ContactsPage/ContactsPage'));
const FeedbacksPage = lazy(() => import('../pages/FeedbacksPage/FeedbacksPage'));
const VacanciesPage = lazy(() => import('../pages/VacanciesPage/VacanciesPage'));

// Правові сторінки
const PublicOfferPage = lazy(() => import('../pages/PublicOfferPage/PublicOfferPage'));
const PrivacyPolicyPage = lazy(() => import('../pages/PrivacyPolicyPage/PrivacyPolicyPage'));

// Блог
const BlogDetailsPage = lazy(() => import('../pages/BlogDetailsPage/BlogDetailsPage'));
const BlogWheelAlignmentPage = lazy(() => import('../pages/BlogDetailsPage/BlogWheelAlignmentPage'));

// Автосервіс
const CarServicePage = lazy(() => import('../pages/CarServicePage/CarServicePage'));
const CarServiceWheelAlignmentRepairPage = lazy(() =>
  import('../pages/CarServicePage/CarServiceWheelAlignmentRepairPage/CarServiceWheelAlignmentRepairPage')
);
const CarServiceEngineRepairPage = lazy(() =>
  import('../pages/CarServicePage/CarServiceEngineRepairPage/CarServiceEngineRepairPage')
);
const CarServiceGearboxRepairPage = lazy(() =>
  import('../pages/CarServicePage/CarServiceGearboxRepairPage/CarServiceGearboxRepairPage')
);
const CarServiceChassisRepairPage = lazy(() =>
  import('../pages/CarServicePage/CarServiceChassisRepairPage/CarServiceChassisRepairPage')
);
const CarServiceElectricRepairPage = lazy(() =>
  import('../pages/CarServicePage/CarServiceElectricRepairPage/CarServiceElectricRepairPage')
);
const CarServiceBodyRepairPage = lazy(() =>
  import('../pages/CarServicePage/CarServiceBodyRepairPage/CarServiceBodyRepairPage')
);
const CarServiceExtraRepairPage = lazy(() =>
  import('../pages/CarServicePage/CarServiceExtraRepairPage/CarServiceExtraRepairPage')
);

// Детейлінг
const CarDetailingServicePage = lazy(() =>
  import('../pages/CarDetailingServicePage/CarDetailingServicePage')
);
const CarDetailingServiceHeadlightPolishingPage = lazy(() =>
  import('../pages/CarDetailingServicePage/CarDetailingServiceHeadlightPolishingPage/CarDetailingServiceHeadlightPolishingPage')
);
const CarDetailingServiceCleaningPage = lazy(() =>
  import('../pages/CarDetailingServicePage/CarDetailingServiceCleaningPage/CarDetailingServiceCleaningPage')
);
const CarDetailingServiceBodyPolishingPage = lazy(() =>
  import('../pages/CarDetailingServicePage/CarDetailingServicePolishingPage/CarDetailingServiceBodyPolishingPage')
);

// Шиномонтаж
const CarTireServicePage = lazy(() => import('../pages/CarTireServicePage/CarTireServicePage'));
const CarTireServiceMountingDismantlingOfTiresPage = lazy(() =>
  import('../pages/CarTireServicePage/CarTireServiceMountingDismantlingOfTiresPage/CarTireServiceMountingDismantlingOfTiresPage')
);
const CarTireServiceTiresRapairPage = lazy(() =>
  import('../pages/CarTireServicePage/CarTireServiceTiresRapairPage/CarTireServiceTiresRapairPage')
);
const CarTireServiceChassisMaintenancePage = lazy(() =>
  import('../pages/CarTireServicePage/CarTireServiceChassisMaintenancePage/CarTireServiceChassisMaintenancePage')
);

export const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    const currentLanguage = i18n.language;

    if (currentPath !== '/' && currentPath.endsWith('/')) {
      navigate(currentPath.slice(0, -1), { replace: true });
      return;
    }

    const language = currentPath.split('/')[1];
    if (language === 'ru' || language === 'ua') {
      i18n.changeLanguage(language);
    } else {
      currentLanguage === 'ru' ? navigate('/ru') : navigate('/ua');
    }
  }, [i18n, navigate, location]);

  return (
    <Routes>
      <Route path="/:lang" element={<Layout />}>
        {/* Головні маршрути */}
        <Route index element={<Home />} />
        <Route path="/:lang/feedbacks" element={<FeedbacksPage />} />
        <Route path="/:lang/blog" element={<BlogPage />} />
        <Route path="/:lang/vacancies" element={<VacanciesPage />} />
        <Route path="/:lang/contacts" element={<ContactsPage />} />

        {/* Блог */}
        <Route path="/:lang/blog/modern-car-service" element={<BlogDetailsPage />} />
        <Route path="/:lang/blog/wheel-alignment" element={<BlogWheelAlignmentPage />} />

        {/* Автосервіс */}
        <Route path="/:lang/car-service" element={<CarServicePage />} />
        <Route path="/:lang/car-service/wheel-alignment" element={<CarServiceWheelAlignmentRepairPage />} />
        <Route path="/:lang/car-service/engine-repair" element={<CarServiceEngineRepairPage />} />
        <Route path="/:lang/car-service/gearbox-repair" element={<CarServiceGearboxRepairPage />} />
        <Route path="/:lang/car-service/chassis-repair" element={<CarServiceChassisRepairPage />} />
        <Route path="/:lang/car-service/electric-repair" element={<CarServiceElectricRepairPage />} />
        <Route path="/:lang/car-service/car-body-repair" element={<CarServiceBodyRepairPage />} />
        <Route path="/:lang/car-service/extra-services" element={<CarServiceExtraRepairPage />} />

        {/* Детейлінг */}
        <Route path="/:lang/car-detailing-service" element={<CarDetailingServicePage />} />
        <Route path="/:lang/car-detailing-service/headlight-polishing" element={<CarDetailingServiceHeadlightPolishingPage />} />
        <Route path="/:lang/car-detailing-service/cleaning" element={<CarDetailingServiceCleaningPage />} />
        <Route path="/:lang/car-detailing-service/body-polishing" element={<CarDetailingServiceBodyPolishingPage />} />

        {/* Шиномонтаж */}
        <Route path="/:lang/car-tire-service" element={<CarTireServicePage />} />
        <Route path="/:lang/car-tire-service/mounting-dismantling-of-tires" element={<CarTireServiceMountingDismantlingOfTiresPage />} />
        <Route path="/:lang/car-tire-service/tires-repair" element={<CarTireServiceTiresRapairPage />} />
        <Route path="/:lang/car-tire-service/chassis-maintenance" element={<CarTireServiceChassisMaintenancePage />} />

        {/* Правові сторінки */}
        <Route path="/:lang/public_offer" element={<PublicOfferPage />} />
        <Route path="/:lang/privacy_policy" element={<PrivacyPolicyPage />} />
      </Route>

      {/* 404 сторінка */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
